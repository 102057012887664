function NotFound() {
    return (
      <div className="Home">
            <header className="Home-header">
              <div>
                <h1 className='logo-text'>Not Found</h1>
              </div>
            </header>
          </div>
    );
  }
  
export default NotFound;