import React, { useState, useEffect, useRef } from "react";
import { components } from "react-select";
import PlacesAutocomplete from "react-places-autocomplete";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactQuill from "react-quill";
import validate from "email-validator";
import CreatableSelect from "react-select/creatable";
import { ClipLoader } from "react-spinners";
import axios from "axios";

import "react-quill/dist/quill.snow.css";
import Option from "./customSelect";
import { useNavigate } from "react-router-dom";

function FormComponent() {
	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage {...props}>
				No Source Found
			</components.NoOptionsMessage>
		);
	};
	//Rich Text Configurations
	var colors = ["#000000", "#ffffff", "#e60000", "#ff9900"];
	var background = ["#000000", "#ffffff", "#facccc", "#ffebcc"];
	const modules = {
		toolbar: [
			[{ header: [1, false] }],
			["bold", "italic", "underline"],
			[{ color: colors }, { background: background }],
			[{ list: "ordered" }, { list: "bullet" }],
		],
	};
	// const localUrl = "http://localhost:3001";
	// const devUrl = "http://3.130.68.140:6001";
	// const prodUrl = "https://api.omegaclients.com";

	// const [selectedOption, setSelectedOption] = useState("none");
	const [emailAddress, setEmailAddress] = useState("");
	const [popup_state, setPopupState] = useState(false);
	const [error_popup_state, setErrorPopupState] = useState(false);
	const [urgency, setUrgencyState] = useState("0");
	const [name, setNameState] = useState("N/A");
	const [phone_number, setPhoneNumberState] = useState("N/A");
	const [email, setEmailState] = useState("N/A");
	const [service_requested, setServiceState] = useState("N/A");
	const [service_val, setServiceValState] = useState("");
	const [address, setAddressState] = useState("N/A");
	const [address_val, setAddressValState] = useState("");
	const [source, setSourceState] = useState("N/A");
	const [source_val, setSourceValState] = useState("N/A");
	const [wait_time] = useState("1");
	const [loading, setLoadingState] = useState(false);
	const [emailValid, setEmailValid] = useState(false);
	const [suggestions] = useState([
		{
			id: 1,
			text: "Stone patio repair",
		},
		{
			id: 2,
			text: "Retaining wall",
		},
		{
			id: 5,
			text: "Construction",
		},
		{
			id: 6,
			text: "Patio countertop",
		},
		{
			id: 4,
			text: "Installing a Mailbox",
		},
		{
			id: 3,
			text: "Mailbox brick needs repair",
		},
	]);
	const options2 = [
		{
			id: "1",
			label: "Leaning Mailbox",
			name: "Leaning Mailbox",
		},
		{
			id: "2",
			label: "Mailbox Repair",
			name: "Mailbox Repair",
		},
		{
			id: "3",
			label: "Mailbox Rebuild",
			name: "Mailbox Rebuild",
		},
		{
			id: "5",
			label: "Retaining Walls",
			name: "Retaining Walls",
		},
		{
			id: "6",
			label: "Tuckpointing",
			name: "Tuckpointing",
		},
		{
			id: "7",
			label: "Brick/Stone Repairs",
			name: "Brick/Stone Repairs",
		},
		{
			id: "8",
			label: "Flowerbed Borders",
			name: "Flowerbed Borders",
		},
		{
			id: "9",
			label: "Concrete Repairs",
			name: "Concrete Repairs",
		},
		{
			id: "10",
			label: "Chimney Repairs",
			name: "Chimney Repairs",
		},
		{
			id: "11",
			label: "Fireplaces",
			name: "Fireplaces",
		},
		{
			id: "12",
			label: "Stucco",
			name: "Stucco",
		},

		{
			id: "13",
			label: "Archways",
			name: "Archways",
		},
		{
			id: "14",
			label: "Stone Patio",
			name: "Stone Patio",
		},
		{
			id: "15",
			label: "Commercial Job",
			name: "Commercial Job",
		},
	];
	const [options, setOptionsState] = useState([
		{
			id: "654645",
			label: "Organic",
			value: "Organic",
		},
		{
			id: "4234234",
			label: "Paper Click",
			value: "Paper Click",
		},
		{
			id: "432093923",
			label: "Omega",
			value: "Omega",
		},
		{
			id: "584378395",
			label: "Omega 2.0",
			value: "Omega 2.0",
		},
	]);
	const [api_ran, setApiRun] = useState(false);
	const [not_found, setNotFoundState] = useState(false);
	const [categoryChange, setCategoryChange] = useState(null);
	const [emailVerifing, setEmailVerifing] = useState("null");
	const selectInputRef = useRef();
	const categoryInputRef = useRef();
	const EmailInputRef = useRef();
	const navigate = useNavigate();

	function clearall() {
		document.getElementById("call-center-form").reset();
		setPopupState(false);
		setErrorPopupState(false);
		setNameState("N/A");
		setPhoneNumberState("N/A");
		setEmailState("N/A");
		setServiceState("N/A");
		setAddressState("N/A");
		setSourceState("N/A");
		setSourceValState("N/A");
		setAddressValState("");
		setServiceValState("");
		setCategoryChange(null);
		setEmailValid(false);
		setEmailVerifing("null");
	}

	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		if (api_ran === false) {
			const requestOption = {
				method: "GET",
				headers: { "Content-Type": "application/json" },
			};
			fetch(
				"https://be79pyhkzh.execute-api.us-east-2.amazonaws.com/dev/lms-sources",
				requestOption
			)
				.then((response) => response.json())
				.then((data) => {
					setOptionsState(data);
					setApiRun(true);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	});
	// useEffect(() => {
	// 	//version api call
	// 	console.log("local version:", version?.toFixed(2));
	// 	axios
	// 		.get(
	// 			prodUrl +
	// 				"/is-lead-form-new-version-available/" + //prod
	// 				// devUrl +
	// 				// 	"/is-lead-form-new-version-available/" + //dev
	// 				// localUrl +
	// 				// "/is-lead-form-new-version-available/" +  //local
	// 				`${version?.toFixed(2)}`,
	// 			{
	// 				headers: {
	// 					"x-access-token": localStorage.getItem("jwtToken"),
	// 					"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
	// 				},
	// 			}
	// 		)
	// 		.then((response) => {
	// 			// Use arrow function here
	// 			console.log("version api response", response.data.data);
	// 			if (response.status === 200) {
	// 				if (response.data.data.is_new_version_available) {
	// 					console.log(
	// 						"New version vaialable",
	// 						response.data.data.current_version
	// 					);
	// 					localStorage.clear();
	// 					// clear the cache
	// 					emptyCache(response.data.data.current_version);
	// 				} else {
	// 					console.log(
	// 						"No new version available",
	// 						response.data.data.current_version
	// 					);
	// 					setVersion(response.data.data.current_version);
	// 				}
	// 			} else {
	// 				console.log("Something went wrong");
	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			console.log("VersionNumber.appVersion", error);
	// 		});
	// }, []);

	// const emptyCache = (current_version) => {
	// 	console.log("versionJson", current_version);
	// 	// setVersion(current_version);
	// 	if ("caches" in window) {
	// 		caches.keys().then((names) => {
	// 			// Delete all the cache files
	// 			names.forEach((name) => {
	// 				caches.delete(name);
	// 			});
	// 		});
	// 	}
	// 	logout();
	// 	// Makes sure the page reloads. Changes are only visible after you refresh.
	// 	// window.location.reload();
	// 	// Makes sure the page reloads. Changes are only visible after you refresh.
	// };
	// const logout = () => {
	// 	localStorage.removeItem("user");
	// 	localStorage.removeItem("nodeJwtToken");
	// 	navigate("/");
	// };

	const onConfirm = (e) => {
		setPopupState(false);
		setErrorPopupState(false);
	};

	const onCancel = () => {
		setPopupState(false);
		setErrorPopupState(false);
	};

	const handleOnChange = (value) => {
		// alert('create api on change')
		this.setState({
			value,
			createdOptions: value, // you can do this a lot smarter
		});
	};
	const handleCategoryChange = (value) => {
		setCategoryChange(value);
	};

	var state_data = {
		urgency: urgency,
		name: name,
		phone_number: phone_number,
		email: email,
		service_requested: service_val === "" ? " N/A" : service_val, //Production
		// svcreqstd: service_val === "" ? " N/A" : service_val, //Staging
		address: address.label ?? address,
		source: source,
		wait_time: wait_time,
		category: categoryChange ? categoryChange : null,
		fakeEmailWarning:
			emailVerifing === "false" || emailVerifing === "null"
				? true
				: false,
	};

	const optionDelete = (event) => {
		options.splice(
			options.indexOf(
				options.find((obj) => {
					if (obj.id === event.id) {
						return obj;
					}
				})
			),
			1
		);
		const requestOption = {
			method: "DELETE",
			headers: { "Content-Type": "application/json" },
		};
		fetch(
			"https://be79pyhkzh.execute-api.us-east-2.amazonaws.com/dev/lms-sources/" +
				event.id,
			requestOption
		)
			.then((response) => response.json())
			.then((data) => {
				setApiRun(false);
			})
			.catch((error) => {
				console.error(error);
			});
		setSourceValState("N/A");
	};

	const customStyles = {
		singleValue: (provided, state) => {
			const paddingLeft = "95px";
			return { ...provided, paddingLeft };
		},
	};

	// // For Staging
	// const handleSubmit = (event) => {
	// 	event.preventDefault();
	// 	setLoadingState(true);
	// 	setTimeout(() => {
	// 		setLoadingState(false);
	// 	}, 2000);
	// 	const requestOptions = {
	// 		method: "POST",
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 			// "x-api-key": "pIqIln2x586GSAPMuMn03acWXuQeMAfp4GIQUlYu",
	// 			"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
	// 		},
	// 		body: JSON.stringify(state_data),
	// 	};

	// 	fetch(
	// 		// "https://knm3mo03xf.execute-api.us-east-2.amazonaws.com/uat/leads",
	// 		// "http://localhost:3001/api/v1/leads/create",
	// 		"http://3.130.68.140:6001/api/v1/leads/create",
	// 		requestOptions
	// 	)
	// 		.then((response) => response.json())
	// 		.then((data) => {
	// 			if (data.success === true) {
	// 				clearall();
	// 				setPopupState(true);
	// 			} else {
	// 				setErrorPopupState(true);
	// 			}
	// 			setLoadingState(false);
	// 		})
	// 		.catch((error) => {
	// 			setErrorPopupState(true);
	// 			setLoadingState(false);
	// 		});
	// };

	// For Production
	const handleSubmit = (event) => {
		event.preventDefault();
		setLoadingState(true);
		setTimeout(() => {
			setLoadingState(false);
		}, 2000);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"x-api-key": "pIqIln2x586GSAPMuMn03acWXuQeMAfp4GIQUlYu",
			},
			body: JSON.stringify(state_data),
		};

		fetch(
			"https://knm3mo03xf.execute-api.us-east-2.amazonaws.com/uat/leads",
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.success === true) {
					clearall();
					setPopupState(true);
				} else {
					setErrorPopupState(true);
				}
				setLoadingState(false);
			})
			.catch((error) => {
				setErrorPopupState(true);
				setLoadingState(false);
			});
	};

	const addDashes = (event) => {
		if (
			event.key != "Backspace" &&
			(event.target.value.length === 3 || event.target.value.length === 7)
		) {
			event.target.value += "-";
		} else if (event.target.value.length > 7) {
			var f = event.target;
			var r = /(\D+)/g,
				npa = "",
				nxx = "",
				last4 = "";
			f.value = f.value.replace(r, "");
			npa = f.value.substr(0, 3);
			nxx = f.value.substr(3, 3);
			last4 = f.value.substr(6);
			f.value = npa + "-" + nxx + "-" + last4;
		}
	};

	const handleName = (event) => {
		event.target.value = event.target.value.trimLeft();
		if (event.target.value.trim === "") {
			setNameState("N/A");
		} else {
			setNameState(event.target.value.trimRight());
		}
	};

	const handleUrgency = (event) => {
		event.target.value = event.target.value.trimLeft();
		if (event.target.value.trim === "") {
			setUrgencyState("N/A");
		} else {
			setUrgencyState(event.target.value.trimRight());
		}
	};

	const handlePhoneNumber = (event) => {
		if (event.target.value.trim() === "") {
			setPhoneNumberState("N/A");
		} else {
			setPhoneNumberState(event.target.value.trim());
		}
	};

	const handleNotFound = (event) => {
		setAddressState("N/A");
		setNotFoundState(!not_found);
	};
	const verifyEmail = async (event) => {
		try {
			if (emailAddress !== event.target.value) {
				if (!emailValid) {
					setEmailAddress(event.target.value);
					if (event.target.value.includes("@"))
						setEmailVerifing("invalid");
					EmailInputRef.current.focus();
				} else if (emailValid) {
					setEmailAddress(event.target.value);
					setEmailVerifing("loading");
					let response = await axios.post(
						`https://api.omegaclients.com/api/v1/leads/checkEmailValidity/${event.target.value}` //prod
						// `http://3.130.68.140:6001/api/v1/leads/checkEmailValidity/${event.target.value}` //dev
						// `http://localhost:3001/api/v1/leads/checkEmailValidity/${event.target.value}` //local
					);
					if (response?.data?.ResponseStatus === 200)
						setEmailVerifing("true");
					else {
						setEmailVerifing("false");
						EmailInputRef.current.focus();
					}
				}
			}
		} catch (error) {
			console.log(error);
			setEmailVerifing("false");
			EmailInputRef.current.focus();
		}
	};
	const handleEmail = async (event) => {
		setEmailValid(false);
		if (event.target.value.trim() === "") {
			setEmailState("N/A");
			setEmailValid(false);
			setEmailVerifing("null");
		} else {
			if (validate.validate(event.target.value.trim())) {
				setEmailValid(true);
			}
			if (!event.target.value.includes("@")) setEmailVerifing("null");
		}
		setEmailState(event.target.value.trim());
	};

	const handleServiceRequest = (value) => {
		setServiceValState(value);
		if (value.trim() === "") {
			setServiceValState("");
		} else {
			setServiceState(
				service_val.trim() === "" ? "N/A" : service_val.trim()
			);
		}
	};

	const handleAddress = (event) => {
		setAddressValState(event);
		if (event.trim() === "" && address_val.trim() === "") {
			setAddressState("N/A");
		} else {
			setAddressState(event);
		}
	};

	const handleCreate = (inputValue) => {
		if (inputValue.trim() != "") {
			const requestOption = {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({
					lable: inputValue,
					value: inputValue,
				}),
			};
			fetch(
				"https://be79pyhkzh.execute-api.us-east-2.amazonaws.com/dev/lms-sources",
				requestOption
			)
				.then((response) => response.json())
				.then((data) => {
					setApiRun(false);
				})
				.catch((error) => {
					console.error(error);
				});
			setApiRun(false);
		}
	};

	const handleSource = (event) => {
		if (event && event.id) {
			// alert('no api')
			setSourceState(event.value);
			setSourceValState(event);
		} else if (event) {
			// alert('api')
			setSourceState(event.value);
			setSourceValState(event);
		} else {
			setSourceState("N/A");
			setSourceValState("N/A");
		}
	};

	const clearAddress = () => {
		setAddressState("N/A");
		setAddressValState("");
	};

	const fillTextArea = (args) => {
		setServiceValState(args);
	};
	return (
		<form
			class="contact100-form validate-form"
			onSubmit={handleSubmit}
			id="call-center-form"
			autocomplete="chrome-off"
		>
			<span class="contact100-form-title">Enter Leads</span>

			{/* <label class='label-input100' for='first-name'>
        Urgency
      </label>
      <div class='wrap-input100 validate-input'>
        <input
          autocomplete='chrome-on'
          min='0'
          max='10'
          title='Enter urgency'
          type='number'
          id='first-urgency'
          class='input100'
          name='urgency'
          placeholder='Enter Urgency'
          onChange={handleUrgency}
        />
        <span class='focus-input100'></span>
      </div> */}

			<label class="label-input100" for="first-name">
				Name <span>*</span>
			</label>
			<div class="wrap-input100 validate-input">
				<input
					title="Enter name"
					name="name"
					type="text"
					id="first-name"
					class="input100"
					placeholder="Enter name"
					onChange={handleName}
					required
					aria-required="true"
				/>
				<span class="focus-input100"></span>
			</div>

			<label class="label-input100" for="first-name">
				Phone Number
			</label>
			<div class="wrap-input100 validate-input">
				<input
					pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,10}$"
					type="text"
					onChange={handlePhoneNumber}
					onKeyUp={addDashes}
					class="input100"
					name="phone_number"
					placeholder="Enter phone number"
				/>
				<span class="focus-input100"></span>
			</div>
			<div
				className={`email-valid ${
					!emailValid && "email-valid-inactive"
				}`}
			>
				<label class="label-input100" for="first-name">
					Email Address <span>*</span>
				</label>
				{emailVerifing === "loading" ? (
					<ClipLoader
						color="#36D7B7"
						size={20}
						height={1}
						width={1}
					/>
				) : emailVerifing === "true" ? (
					<div className="email-valid-text">
						<p>Email Verified</p>
						<img src={require("../assets/check.png")} alt="" />
					</div>
				) : emailVerifing === "false" ? (
					<div className="email-valid-text email-valid-text-invalid">
						<p>Email does not exist</p>
						<img src={require("../assets/wrong2.png")} alt="" />
					</div>
				) : (
					emailVerifing === "invalid" && (
						<div className="email-valid-text email-valid-text-invalid">
							<p>Enter a valid email address</p>
							<img src={require("../assets/wrong2.png")} alt="" />
						</div>
					)
				)}
			</div>
			<div class="wrap-input100 validate-input">
				<input
					title="Enter email address"
					type="email"
					onChange={handleEmail}
					onBlur={verifyEmail}
					required
					class="input100"
					name="email"
					placeholder="Enter email address"
					ref={EmailInputRef}
				/>
				<span class="focus-input100"></span>
			</div>
			<label class="label-input100" for="first-name">
				Service Requested
			</label>
			<div class="wrap-input100 validate-input my-react-quill">
				<ReactQuill
					modules={modules}
					style={{
						width: "100%",
						minHeight: "150px",
						height: "150px",
						backgroundColor: "white",
						fontSize: "18px",
						fontFamily: "Lato !important",
						".ql-editor p": {
							fontSize: "18px", // Adjust font size as desired
						},
					}}
					className="ql-container ql-height "
					value={service_val}
					onChange={handleServiceRequest}
					placeholder="Enter service requested"
				/>
				<span class="focus-input100"></span>
			</div>
			<div className="mb-3 wrap-input100 border-none">
				{suggestions.map((object, i) => (
					<div
						key={i}
						className="pills"
						onClick={() => fillTextArea(object.text)}
					>
						{object.text}
					</div>
				))}
			</div>
			<label class="label-input100" for="first-name">
				Address
			</label>
			<div class="wrap-input100 validate-input" id="address-ip">
				{/* <input autocomplete="chrome-on" title="Enter address" type="text" id="first-address" class="input100" name="first-address" placeholder="Enter address" onChange={handleAddress} /> */}

				<PlacesAutocomplete
					onChange={handleAddress}
					value={address_val}
				>
					{({
						getInputProps,
						suggestions,
						getSuggestionItemProps,
						loading,
					}) => (
						<div>
							{!loading && address_val !== "" ? (
								<div
									className="loading-add"
									onClick={clearAddress}
								>
									<i className="bi bi-x-lg text-muted m-auto"></i>
								</div>
							) : null}
							<input
								{...getInputProps({
									placeholder: "Enter address",
									className: "input100",
									id: "searchadd",
								})}
							/>
							{suggestions.length > 0 ? (
								<div>
									{loading && (
										<div className="loading-add">
											<span
												class="spinner-border spinner-border-sm text-muted m-auto"
												role="status"
												aria-hidden="true"
											></span>
										</div>
									)}
									<div className="autocomplete-dropdown-container">
										{suggestions.map((suggestion) => {
											const className = suggestion.active
												? "suggestion-item--active"
												: "suggestion-item";
											// inline style for demonstration purpose
											const style = suggestion.active
												? {
														backgroundColor:
															"#fafafa",
														cursor: "pointer",
												  }
												: {
														backgroundColor:
															"#ffffff",
														cursor: "pointer",
												  };
											return (
												<div
													{...getSuggestionItemProps(
														suggestion,
														{
															className,
															style,
														}
													)}
												>
													<div className="item-margins">
														{suggestion.description}
													</div>
												</div>
											);
										})}
									</div>
								</div>
							) : null}
						</div>
					)}
				</PlacesAutocomplete>

				<br />
				{/*<span class="focus-input100"></span>
                 <div class="form-check mt-2">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={handleNotFound} />
                    <label class="label-input100" for="flexCheckDefault">
                        Can't find the address
                    </label>
                </div> */}
			</div>
			{/* <label class="label-input100" for="first-name">
				Source
			</label>
			<div class="wrap-input100 validate-input"> */}
			{/* <input autocomplete="chrome-off" title="Enter name" type="text" id="first-name" class="input100" name="first-name" placeholder="Enter name" onChange={handleName} required /> */}
			{/* <CreatableSelect
					singleValue={customStyles}
					selectInputRef={selectInputRef}
					isClearable
					placeholder="Select source"
					class="input100"
					required
					onCreateOption={handleCreate}
					components={{ Option, NoOptionsMessage }}
					styles={{ noOptionsMessage: (base) => ({ ...base }) }}
					options={options}
					handleOnChange={handleOnChange}
					onChange={handleSource}
					optionDelete={optionDelete}
					id="exampleInputsrc"
					value={source_val}
				/>
				<span class="focus-input100"></span>
			</div> */}
			<label class="label-input100" for="first-name">
				Category
			</label>
			<div class="wrap-input100 validate-input">
				<CreatableSelect
					singleValue={customStyles}
					selectInputRef={categoryInputRef}
					isClearable
					placeholder="Select category"
					class="input100"
					required={false}
					components={{ NoOptionsMessage }}
					styles={{
						option: (provided, state) => ({
							...provided,
							background: "white", //state.isSelected ? "blue" : "white",
							color: "black",
							"&:hover": {
								backgroundColor: "#d2e8fb",
								color: "black",
							},
						}),
						noOptionsMessage: (base) => ({ ...base }),
					}}
					options={options2}
					//   handleOnChange={handleCategoryChange}
					onChange={handleCategoryChange}
					id="exampleInputsrc"
					value={categoryChange}
				/>
				<span class="focus-input100"></span>
			</div>
			<div class="container-contact100-form-btn">
				{loading === true ? (
					<button class="contact100-form-btn">
						<span
							class="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					</button>
				) : (
					<button class="contact100-form-btn" type="submit">
						<span>
							Submit
							<i class="zmdi zmdi-arrow-right m-l-8"></i>
						</span>
					</button>
				)}
			</div>
			{popup_state === true ? (
				<SweetAlert
					className="small-size"
					btnSize="md"
					title={"Lead Added Successfully"}
					success
					customIcon="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Check_green_circle.svg/2048px-Check_green_circle.svg.png"
					showConfirm
					onConfirm={onConfirm}
					onCancel={onCancel}
				></SweetAlert>
			) : null}
			{error_popup_state === true ? (
				<SweetAlert
					className="small-size"
					btnSize="md"
					title={"Something Went Wrong! Please Try again."}
					danger
					showConfirm
					confirmBtnText="Try Again!"
					onConfirm={onConfirm}
					onCancel={onCancel}
				></SweetAlert>
			) : null}
		</form>
	);
}

export default FormComponent;
