import FormComponent from "../../components/formComponent";
import { GoogleLogout, useGoogleLogout } from "react-google-login";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { useEffect, useState } from "react";

function Home() {
	const [version, setVersion] = useState(2.08);
	const navigate = useNavigate();

	const localUrl = "http://localhost:3001";
	const devUrl = "http://3.130.68.140:6001";
	const prodUrl = "https://api.omegaclients.com";
	useEffect(() => {
		//version api call
		console.log("local version:", version?.toFixed(2));
		axios
			.get(
				prodUrl +
					"/is-lead-form-new-version-available/" + //prod
					// devUrl +
					// 	"/is-lead-form-new-version-available/" + //dev
					// localUrl +
					// "/is-lead-form-new-version-available/" +  //local
					`${version?.toFixed(2)}`,
				{
					headers: {
						"x-access-token": localStorage.getItem("jwtToken"),
						"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
					},
				}
			)
			.then((response) => {
				// Use arrow function here
				console.log("version api response", response.data.data);
				if (response.status === 200) {
					if (response.data.data.is_new_version_available) {
						console.log(
							"New version vaialable",
							response.data.data.current_version
						);
						localStorage.clear();
						// clear the cache
						emptyCache(response.data.data.current_version);
					} else {
						console.log(
							"No new version available",
							response.data.data.current_version
						);
						setVersion(response.data.data.current_version);
					}
				} else {
					console.log("Something went wrong");
				}
			})
			.catch(function (error) {
				console.log("VersionNumber.appVersion", error);
			});
	}, []);

	const emptyCache = (current_version) => {
		console.log("versionJson", current_version);
		// setVersion(current_version);
		if ("caches" in window) {
			caches.keys().then((names) => {
				// Delete all the cache files
				names.forEach((name) => {
					caches.delete(name);
				});
			});
		}
		logout2();
		// Makes sure the page reloads. Changes are only visible after you refresh.
		// window.location.reload();
		// Makes sure the page reloads. Changes are only visible after you refresh.
	};
	const logout2 = () => {
		localStorage.removeItem("user");
		localStorage.removeItem("nodeJwtToken");
		localStorage.setItem("isLogOutForNewVersion", true);

		navigate("/");
	};

	const responseGoogle = (response) => {
		console.log(response);
	};
	const responseGoogleError = (error) => {
		console.error(error);
	};

	const logout = () => {
		localStorage.removeItem("user");
		navigate("/");
	};

	const onFailure = (response) => {
		console.log(response);
	};
	const onLogoutSuccess = (response) => {
		console.log("User Logged out");
		localStorage.removeItem("user");
		navigate("/");
	};
	const clientId =
		"1040800746055-78escdor0om2ettno6thi9ajrsgm8ubt.apps.googleusercontent.com";

	const { signOut, loaded } = useGoogleLogout({
		onFailure,
		onLogoutSuccess,
		clientId,
	});

	// Similar to componentDidMount and componentDidUpdate:
	useEffect(() => {
		if (!localStorage.getItem("user")) {
			signOut();
		}
	});
	console.log("version", version);
	return (
		// <div className='mb-4'>
		//     <nav class="navbar navbar-expand-lg bg-primary navbar-dark mb-3 p-0">
		//         <div class="container">
		//             <a class="navbar-brand font-weight-bold font-size-larg"><img className='me-3' src={process.env.PUBLIC_URL + 'logo_main.png'} width="50px;" />LMS Lead Data Entry Form</a>
		//             <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
		//                 <span class="navbar-toggler-icon"></span>
		//             </button>
		//             <div class="collapse navbar-collapse" id="navbarText">
		//                 <ul class="navbar-nav me-auto mb-2 mb-lg-0">
		//                     {/* <li class="nav-item">
		//                         <a class="nav-link active" aria-current="page" href="#">Home</a>
		//                     </li>
		//                     <li class="nav-item">
		//                         <a class="nav-link" href="#">Features</a>
		//                     </li>
		//                     <li class="nav-item">
		//                         <a class="nav-link" href="#">Pricing</a>
		//                     </li> */}
		//                 </ul>
		//                 <span class="navbar-text">
		//                     {/* <span onClick={logout} className='font-weight-bold' cl><i class="fa-solid fa-arrow-right-from-bracket"></i>Logout</span> */}
		//                     <GoogleLogout
		//                         clientId="1040800746055-78escdor0om2ettno6thi9ajrsgm8ubt.apps.googleusercontent.com"
		//                         buttonText="Logout"
		//                         render={renderProps => (
		//                             <span onClick={renderProps.onClick} className='font-weight-bold text-white cursor-pointer font-size-semi'><i class="bi bi-power font-size-semi me-2 font-weight-bold text-white"></i>&nbsp;Logout</span>
		//                         )}
		//                         onLogoutSuccess={logout}
		//                     >
		//                     </GoogleLogout>
		//                 </span>
		//             </div>
		//         </div>
		//     </nav>
		//     <FormComponent />
		// </div>
		<div>
			<nav class="fixed-top navbar navbar-expand-lg bg-grad navbar-light p-0">
				<div class="container" style={{ position: "relative" }}>
					<a class="navbar-brand font-weight-bold font-size-larg">
						<img
							className="me-3"
							src={process.env.PUBLIC_URL + "logo_main.png"}
							width="50px;"
						/>
						LMS Lead Data Entry Form
					</a>
					<a className="version-text">V.{version}</a>
					<button
						class="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarText"
						aria-controls="navbarText"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarText">
						<ul class="navbar-nav me-auto mb-2 mb-lg-0">
							{/* <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#">Home</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Features</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#">Pricing</a>
                            </li> */}
						</ul>
						<span class="navbar-text">
							{/* <span onClick={logout} className='font-weight-bold' cl><i class="fa-solid fa-arrow-right-from-bracket"></i>Logout</span> */}
							<GoogleLogout
								clientId="1040800746055-78escdor0om2ettno6thi9ajrsgm8ubt.apps.googleusercontent.com"
								buttonText="Logout"
								render={(renderProps) => (
									<span
										onClick={renderProps.onClick}
										className="font-weight-bold cursor-pointer font-size-semi"
									>
										<b>
											<i
												class="bi bi-power font-size-semi me-2 font-weight-bold"
												style={{
													"font-size": "1.2rem",
												}}
											></i>
										</b>
										&nbsp;Logout
									</span>
								)}
								onLogoutSuccess={logout}
							></GoogleLogout>
						</span>
					</div>
				</div>
			</nav>
			<div class="container-contact100 mt-5">
				<div class="wrap-contact100 mt-5 bg-grey">
					<FormComponent />
				</div>
			</div>
		</div>
	);
}

export default Home;
