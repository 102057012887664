import { GoogleLogin, useGoogleLogout } from "react-google-login";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

function Home() {
	const [error_popup_state, setErrorPopupState] = useState(false);
	const [loading, setLoadingState] = useState(false);
	const navigate = useNavigate();

	const onFailure = (response) => {
		console.log(response);
	};
	const onLogoutSuccess = (response) => {
		console.log("User Logged out");
	};
	const clientId =
		"1040800746055-78escdor0om2ettno6thi9ajrsgm8ubt.apps.googleusercontent.com";
	const { signOut, loaded } = useGoogleLogout({
		onFailure,
		onLogoutSuccess,
		clientId,
	});

	const responseGoogle = (response) => {
		console.log(response.profileObj.email);
		setLoadingState(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"app-auth-token": 'tI]iLCJ7J9%3Y%";hTL9',
			},
			body: JSON.stringify({
				email: response.profileObj.email,
			}),
		};
		fetch(
			"https://api.omegaclients.com/api/v1/users/authenticate",
			requestOptions
		)
			.then((response) => response.json())
			.then((data) => {
				if (data.success == true) {
					setLoadingState(false);
					// console.log("response", response.accessToken);
					localStorage.setItem("nodeJwtToken", response.accessToken);
					localStorage.setItem("user", JSON.stringify(response));
					handleClick();
				} else {
					console.log(data);
					setLoadingState(false);
					setErrorPopupState(true);
					signOut();
				}
			})
			.catch((error) => {
				setLoadingState(false);
				console.error(error);
				setErrorPopupState(true);
			});
	};
	const responseGoogleError = (error) => {
		console.error(error);
		setLoadingState(false);
		// if(login_state === true){
		//     handleClick()
		// }
	};
	function handleClick() {
		navigate("/home");
	}
	const isLogOutForNewVersion = localStorage.getItem("isLogOutForNewVersion");

	console.log("isLogOutForNewVersion", isLogOutForNewVersion);
	return (
		<div className="App">
			<header className="App-header">
				<div>
					<h2 className="al-left lable-text text-blue lh-0 m-0 mb-3">
						Welcome to
					</h2>
					<h1 className="al-left logo-text display-5">
						Leads
						<br />
						Data Entry
						<br />
						System
						<br />
					</h1>
					{loading === false ? (
						<GoogleLogin
							className="login-btn mt-5"
							clientId="1040800746055-78escdor0om2ettno6thi9ajrsgm8ubt.apps.googleusercontent.com"
							buttonText="Sign in with Google"
							onSuccess={responseGoogle}
							onFailure={responseGoogleError}
							isSignedIn={
								isLogOutForNewVersion === true ? false : true
							}
							// isSignedIn={
							// 	isLogOutForNewVersion &&
							// 	isLogOutForNewVersion != null
							// 		? false
							// 		: true
							// }
							// cookiePolicy={'single_host_origin'}
						/>
					) : (
						// <div className='mt-5'>
						//     <img src={process.env.PUBLIC_URL + 'loading.gif'} width="100px" className='mt-1' />
						// </div>
						<div
							class="spinner-border mt-5"
							style={{
								width: "3rem",
								height: "3rem",
								color: "#1f68fb",
							}}
							role="status"
						>
							<span class="visually-hidden">Loading...</span>
						</div>
					)}

					{error_popup_state === true ? (
						<p className="font-weight-bold text-danger font-size-small">
							<b>User not authenticated.</b>
						</p>
					) : null}
				</div>
			</header>
		</div>
	);
}

export default Home;
