import './App.css';
import Router from './routes/index'


function App() {
  return (
    <Router/>
  );
}
export default App;
