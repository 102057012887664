import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from '../views/Auth/Login';
import NotFound from '../views/Errors/NotFound';
import Home from '../views/Dashboard/Home';


function AppRouter(){
    return(
        <Router>
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/home" element={<Home/>}></Route>
            <Route path="*" element={<NotFound/>}></Route>
          </Routes>
        </Router>
    )
}

export default AppRouter;